// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dexes-list {
  list-style-type: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.4rem;
  margin-top: 1.5rem;
}
@media screen and (max-height: 576px) {
  .dexes-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
.dexes-list li {
  background: #0A1633;
  border-radius: 10px;
  padding: 5px 15px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dexes-list li img {
  max-width: 100%;
  max-height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/containers/Getcmdx/GetcmdxModal/index.less"],"names":[],"mappings":"AAEA;EACI,qBAAA;EACA,eAAA;EACA,aAAA;EACA,qCAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAEI;EAAA;IACI,qCAAA;EACN;AACF;AAVA;EAWQ,mBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAER;AAnBA;EAmBY,eAAA;EACA,gBAAA;AAGZ","sourcesContent":["@import \"../../../assets/less/variables.less\";\n\n.dexes-list{\n    list-style-type: none;\n    padding-left: 0;\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-gap: 1.4rem;\n    margin-top: 1.5rem;\n    @media screen and (max-height:576px) {\n        grid-template-columns: repeat(2, 1fr);\n    }\n    li {\n        background: #0A1633;\n        border-radius: 10px;\n        padding: 5px 15px;\n        min-height: 60px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        img {\n            max-width: 100%;\n            max-height: 30px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
