import React from "react";
import "./index.less";

const VideoCard = ({onClick, videoImg, name, heading1, heading2, date, logo, views}) => {
    return (
        <div className="video-card" onClick={onClick}>
            <div className="video-card-inner">
                <div className="video-card-img">
                    <img className="cover-img" src={videoImg} alt={name} />
                    <div className="overlay-upper">
                        <div className="logos">
                            <img className="logo-img" alt={logo} src={logo} />
                        </div>
                        <div>
                            <h4><span>{heading1}</span> {heading2}</h4>
                        </div>
                    </div>
                </div>
                <div className="video-card-bottom">
                    <h3>{name}</h3>
                    <ul><li>{views} Views</li> <li>{date}</li></ul>
                </div>
            </div>
        </div>
    )
}

export default VideoCard